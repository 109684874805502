import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/styles";
import { isTextTruncated } from "@utils/text-utils";
import React, {
  cloneElement,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

const ViewAllTooltip = ({ children = null, width = "" }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const ref = useRef(null);
  const ChildrenCloneElement = cloneElement(children, { ref });
  const childRef = ChildrenCloneElement.ref;
  const content = childRef?.current?.innerText || "";

  const StyledTooltip = useMemo(
    (maxWidth) =>
      withStyles({
        tooltip: {
          maxWidth: maxWidth || "960px",
          lineHeight: "14px",
          fontSize: "12px",
          fontWeight: "400",
          padding: "4px 8px",
        },
        tooltipPlacementBottom: {
          marginTop: "-4px",
        },
      })(Tooltip),
    [width],
  );

  const handleResize = () => {
    setShowTooltip(isTextTruncated(childRef));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [childRef, content]);

  return showTooltip ? (
    <StyledTooltip placement="bottom" title={content}>
      {ChildrenCloneElement}
    </StyledTooltip>
  ) : (
    <Fragment>{ChildrenCloneElement}</Fragment>
  );
};

export default ViewAllTooltip;
