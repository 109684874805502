import {
  ANDROID_DOWNLOAD_LINK,
  ANDROID_INSTALL_GUIDE,
  appJson,
  IOS_DOWNLOAD_LINK,
  IOS_INSTALL_GUIDE,
  REACT_APP_ENV,
  scm,
  USER_GUIDE_URL_EN,
  USER_GUIDE_URL_ZH_HANS,
  USER_GUIDE_URL_ZH_HANT,
} from "@components/AppEnv";
import getYear from "date-fns/getYear";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import { useIntl } from "react-intl";

import * as S from "./Footer.module.scss";

const QRCode = dynamic(() => import("qrcode.react"));

const {
  custom,
  icp,
  customized_copyright: customizedCopyright,
} = scm.footer ?? {};

const SHOW_DOWNLOAD_SCREEN = scm.download.show_app_download_screen;

const Footer = () => {
  const intl = useIntl();
  const router = useRouter();

  const handleClick = (event, param) => {
    event.preventDefault();
    router.push(`/${param}`);
  };
  const handleUserGuideURL = () => {
    switch (intl.locale) {
      case "zh-Hans":
        return USER_GUIDE_URL_ZH_HANS;
      case "zh-Hant":
        return USER_GUIDE_URL_ZH_HANT;
      case "en":
      default:
        return USER_GUIDE_URL_EN;
    }
  };

  const showIcp = !!icp && icp.length >= 2;
  const showCustomizedCopyright = !!customizedCopyright;

  const iOSDownloadLink = IOS_DOWNLOAD_LINK || "";
  const androidDownloadLink = ANDROID_DOWNLOAD_LINK || "";
  return (
    <footer>
      <div className={S.footer} id="footer">
        <div className={S.content}>
          <div className={S.copy_right}>
            &#xA9;{` ${getYear(new Date())} `}
            {showCustomizedCopyright
              ? customizedCopyright
              : "Delta Electronics, Inc. All Rights Reserved."}
            {showIcp ? (
              <div className={S.security_wrapper}>
                <p className={S.security_content_wrapper}>{icp[0]}</p>
                <div className={S.security_content_icon}>
                  <Image
                    src={"/images/security.png"}
                    width={20}
                    height={20}
                    alt="icp"
                  />
                </div>
                <p className={S.security_content_wrapper}>{icp[1]}</p>
              </div>
            ) : null}
          </div>
          <div>
            <div className={S.links}>
              {SHOW_DOWNLOAD_SCREEN ? (
                <div className={S.nav_link}>
                  <a
                    href={"/download"}
                    onClick={(event) => handleClick(event, "download")}>
                    {intl.formatMessage({
                      id: "common.pageName.downloadApp",
                      defaultMessage: "Download our App",
                    })}
                  </a>
                  <div className={S.divider}>
                    <div />
                  </div>
                </div>
              ) : null}

              <div className={S.nav_link}>
                <a href={handleUserGuideURL()} target="_blank" rel="noreferrer">
                  {intl.formatMessage({
                    id: "common.pageName.userGuide",
                    defaultMessage: "User Guide",
                  })}
                </a>
                <div className={S.divider}>
                  <div />
                </div>
              </div>

              <div className={S.nav_link}>
                <a
                  href={"/terms"}
                  onClick={(event) => handleClick(event, "terms")}>
                  {intl.formatMessage({
                    id: "common.pageName.termsOfUse",
                    defaultMessage: "Terms Of Use",
                  })}
                </a>
                <div className={S.divider}>
                  <div />
                </div>
              </div>
              <div className={S.nav_link}>
                <a
                  href={"/privacy"}
                  onClick={(event) => handleClick(event, "privacy")}>
                  {intl.formatMessage({
                    id: "common.pageName.privacyPolicy",
                    defaultMessage: "Privacy Policy",
                  })}
                </a>
                <div className={S.divider}>
                  <div />
                </div>
              </div>
              <div className={S.nav_link}>
                <a
                  href={"/dataPolicy"}
                  onClick={(event) => handleClick(event, "dataPolicy")}>
                  {intl.formatMessage({
                    id: "common.pageName.dataCollection",
                    defaultMessage: "Data Collection",
                  })}
                </a>
                <div className={S.divider}>
                  <div />
                </div>
              </div>
              <div className={S.nav_link}>
                <span>{appJson.version[REACT_APP_ENV]}</span>
              </div>
            </div>
            {custom === "qrcode" ? (
              <div className={S.qrcode}>
                <div className={S.code}>
                  {intl.formatMessage({
                    id: "footer.text.academyMobileAppForIosAndAndroid",
                    defaultMessage: "Academy mobile app for iOS and Android",
                  })}
                  <div className={S.suggestion}>
                    {intl.formatMessage({
                      id: "footer.text.iosSuggestion",
                      defaultMessage:
                        "For iOS users, please use the built-in Camera App to scan the QR code",
                    })}
                  </div>
                  <div className={S.code_img}>
                    <div>
                      <a href={iOSDownloadLink}>
                        <QRCode
                          value={iOSDownloadLink}
                          size={76}
                          bgColor={"#ffffff"}
                          fgColor={"#272727"}
                          level={"L"}
                          includeMargin={false}
                          renderAs={"svg"}
                        />
                      </a>
                      <a href={IOS_INSTALL_GUIDE}>
                        <span style={{ textDecoration: "underline" }}>
                          {intl.formatMessage({
                            id: "footer.text.iosQuickGuide",
                            defaultMessage: "iOS Quick Start Guide",
                          })}
                        </span>
                      </a>
                    </div>
                    <div>
                      <a href={androidDownloadLink}>
                        <QRCode
                          value={androidDownloadLink}
                          size={76}
                          bgColor={"#ffffff"}
                          fgColor={"#272727"}
                          level={"L"}
                          includeMargin={false}
                          renderAs={"svg"}
                        />
                      </a>
                      <a href={ANDROID_INSTALL_GUIDE}>
                        <span style={{ textDecoration: "underline" }}>
                          {intl.formatMessage({
                            id: "footer.text.androidQuickGuide",
                            defaultMessage: "Android Quick Start Guide",
                          })}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
