import { scm } from "@components/AppEnv";
import clientAPI from "@utils/axios/client-api";

const useTmsAdapter = scm?.explore?.use_tms_adapter;
const postCreateBundleCourse = ({ title, description }) =>
  clientAPI
    .post("/api/v1/catalog/campaign/console", { title, description })
    .then((res) => res.data);

const deleteBundleCourse = ({ id }) =>
  clientAPI
    .delete(`/api/v1/catalog/campaign/console/${id}`)
    .then((res) => res.data);

const getConsoleBundleCourseList = (pageNum) =>
  clientAPI
    .get(
      `/api/v1/catalog/campaign/console?pageSize=7&sortKey=create_time&sortOrder=-1&pageNum=${pageNum}`,
    )
    .then((res) => res.data);

const updateCampaignInfo = (props) => {
  const { campaignId, updateCampaignData } = props;
  return clientAPI
    .put(`/api/v1/catalog/campaign/console/${campaignId}`, updateCampaignData)
    .then((response) => response.data);
};

const getCampaignInfoPoint = (campaignId) =>
  clientAPI
    .get(`/api/v1/catalog/campaign/console/${campaignId}`)
    .then((res) => res.data);

const searchCourseByTypePoint = (type, keyword) =>
  clientAPI
    .get(
      `/api/v1/${
        useTmsAdapter ? "tms-adapter/" : ""
      }search/catalog/${type}/course?keyword=${keyword}`,
    )
    .then((res) => res.data);

const getEnrollStatus = ({ itemId }) =>
  clientAPI
    .get(`/api/v1/learning-system/course/${itemId}/enroll_status`)
    .then((res) => res.data);

const getCampaignEnrollStatusPoint = ({ itemId }) =>
  clientAPI
    .get(`/api/v1/order-system/campaign/${itemId}/enroll_status`)
    .then((res) => res.data);

export {
  deleteBundleCourse,
  getCampaignEnrollStatusPoint,
  getCampaignInfoPoint,
  getConsoleBundleCourseList,
  getEnrollStatus,
  postCreateBundleCourse,
  searchCourseByTypePoint,
  updateCampaignInfo,
};
