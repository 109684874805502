const { scm } = require("./nodeEnv");

const pagePermissionKey = {
  // #region root menu
  generalSettings: {
    permissionKey: "ac-console-6",
  },
  marketing: {
    permissionKey: "ac-rolemanagement-3",
  },
  finances: {
    permissionKey: "ac-console",
  },
  analytics: {
    permissionKey: "ac-console-2",
  },
  courseware: {
    permissionKey: "ac-studio-2",
  },
  certificateTemplate: {
    permissionKey: "ac-rolemanagement-1", // admin permission
  },
  // group menu
  organization: {
    permissionKey: "ac-console",
  },
  customization: {
    permissionKey: "ac-console",
  },
  userAccountSetting: {
    permissionKey: "ac-console",
  },
  // #endregion

  // #region Sub Pages
  banner: {
    permissionKey: "ac-console-7",
  },
  categories: {
    permissionKey: "ac-console-6",
  },
  campaign: {
    permissionKey: "ac-console-10",
  },
  coupons: {
    permissionKey: "ac-console-11",
  },
  contentCompletion: {
    permissionKey: "ac-console-8",
  },
  courseWhitelist: {
    permissionKey: "ac-console-102",
  },
  courseOverviewReport: {
    permissionKey: "ac-console-13",
  },
  groupManagement: {
    permissionKey: "ac-console-15",
  },
  learnerProgressReport: {
    permissionKey: "ac-console-2",
  },
  memberPermission: {
    permissionKey: "ac-console-14",
  },
  memberManagement: {
    permissionKey: "ac-console-14",
  },
  memberInformation: {
    permissionKey: "ac-console-14",
  },
  myCourse: {
    permissionKey: "ac-studio-2",
  },
  myLibrary: {
    permissionKey: "ac-studio-2",
  },
  orderManagement: {
    permissionKey: "ac-console-12",
  },
  overviewDashboard: {
    permissionKey: "ac-console-2",
  },
  paymentSetting: {
    permissionKey: "ac-console-9",
  },
  priceVisibility: {
    permissionKey: "ac-console-16",
  },
  discussionSetting: {
    permissionKey: "ac-console-17",
  },
  personalInformation: {
    permissionKey: "ac-console-18",
  },
  // #endregion
};

// DKAHR should hide ["coupons", "orderManagement", "campaign"]
if (scm.explore.use_tms_adapter) {
  const filterKey = ["coupons", "orderManagement", "campaign"];
  filterKey.forEach((key) => {
    if (pagePermissionKey[key]) {
      pagePermissionKey[key].disabled = true;
    } else {
      throw Error(`${key} is not found`);
    }
  });
}

module.exports = pagePermissionKey;
