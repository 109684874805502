import { getCourseContentUrl } from "@router/utils";
import { getEnrollStatus } from "@utils/apiServices/campaign";
import { getPublishedCourseInfo } from "@utils/apiServices/explore";
import { getUserUnfinishedField } from "@utils/apiServices/user";

const getChapterItemList = ({ chapters, itemArray }) => {
  chapters.forEach((chapter) => {
    chapter.items.forEach((item) => {
      itemArray.push(item);
    });
  });
};

const pageDirectJudgment = ({ router, url, reason }) => {
  if (router.query.fromPage) {
    router.push(`/${router.query.fromPage}#${reason}`);
  } else {
    router.push(`${url}#${reason}`);
  }
};

export const courseDirectHelper = async ({
  courseId,
  previewType = "course",
  router,
  onError,
}) => {
  try {
    if (!courseId) {
      throw Error("empty course id");
    }
    const { url, error } = await getCourseContentUrl({
      courseId,
      getLearningSyllabus: () => getPublishedCourseInfo(courseId, previewType),
      getUserUnfinishedField,
      getEnrollStatus,
    });
    if (error) {
      if (typeof onError === "function") onError();
      pageDirectJudgment({
        router,
        url: `${url}`,
        reason: error,
      });
      throw error;
    } else {
      if (router) {
        router.push(`${url}`);
        return url;
      }
      return url;
    }
  } catch (error) {
    console.error("courseDirect", error);
    return "/";
  }
};

const checkLearningItemExisted = ({
  courseInfo = null,
  learningType = "",
  learningItemId = "",
}) => {
  switch (learningType) {
    case "attachment":
      return courseInfo.courseContent?.attachmentChapter !== null;
    case "video":
    case "doc":
    case "document":
    case "h5":
    case "web_link":
    case "asmt":
    case "assessment":
    case "survey": {
      const { chapters = [], previewVideo = {} } =
        courseInfo?.courseContent || {};
      const itemArray = [];
      if (previewVideo?.id) {
        itemArray.push({ ...previewVideo, type: "video" });
      }
      getChapterItemList({ chapters, itemArray });
      const isTarget = (item) =>
        item.id === learningItemId && item.type === learningType;
      return typeof itemArray.find(isTarget) !== "undefined";
    }
    default:
      return false;
  }
};

const directToContentNotFound = ({
  router,
  courseId,
  courseInfo,
  learningItemId,
}) => {
  const { chapters = [], previewVideo = {} } = courseInfo?.courseContent || {};
  const itemArray = [];
  if (previewVideo?.id) {
    itemArray.push({ ...previewVideo, type: "video" });
  }
  getChapterItemList({ chapters, itemArray });
  const firstContent = itemArray[0];
  if (firstContent) {
    const { type, id } = firstContent;
    if (learningItemId === id) {
      // already first content id
      pageDirectJudgment({
        router,
        url: `/course/preview/${courseId}`,
        reason: "contentNotFound",
      });
    } else {
      pageDirectJudgment({
        router,
        url: `/course/${courseId}/${type}/${id}`,
        reason: "contentNotFound",
      });
    }
  } else {
    pageDirectJudgment({
      router,
      url: `/course/preview/${courseId}`,
      reason: "contentNotFound",
    });
  }
};

const directToCourseNotFound = ({ router }) => {
  pageDirectJudgment({
    router,
    url: "/explore",
    reason: "courseNotFound",
  });
};

const directToSyllabusError = ({ router, courseId }) => {
  pageDirectJudgment({
    router,
    url: `/course/preview/${courseId}`,
    reason: "syllabusError",
  });
};

const directToHasNotBeenEnrolledError = ({ router, courseId }) => {
  pageDirectJudgment({
    router,
    url: `/course/preview/${courseId}`,
    reason: "courseHasNotBeenEnrolled",
  });
};

export const directToNoLogin = ({
  router,
  courseId,
  learningType,
  learningItemId,
}) => {
  if (learningItemId && learningType) {
    pageDirectJudgment({
      router,
      url: `/course/preview/${courseId}?redirect_uri=/course/${courseId}/${learningType}/${learningItemId}`,
      reason: "401",
    });
  } else {
    pageDirectJudgment({
      router,
      url: `/course/preview/${courseId}`,
      reason: "401",
    });
  }
};

export {
  checkLearningItemExisted,
  directToContentNotFound,
  directToCourseNotFound,
  directToHasNotBeenEnrolledError,
  directToSyllabusError,
  pageDirectJudgment,
};

export const translateToSkillLevel = (number) => {
  switch (number) {
    case 1:
      return "beginner";
    case 2:
      return "intermediate";
    case 3:
      return "advanced";
    default:
      return "beginner";
  }
};
