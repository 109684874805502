import { appRootPath } from "@configs/nodeEnv";
import pagePermitKeyList from "@configs/page-permissionKey";
import { isTokenAvailable } from "@utils/auth-utils";
import { checkPageIsPublic } from "@utils/route-utils";
import { getUserPrivacy, getUserPrivilege } from "@utils/server/api-point";
import { getCookies } from "cookies-next";
import qs from "querystringify";
import { findIndex } from "ramda";

export const checkIsProvider = (userPrivilege) => {
  const providerToken = (item) => item === "ac-studio-1";
  return userPrivilege ? findIndex(providerToken, userPrivilege) !== -1 : false;
};

export const checkIsInstructor = (userPrivilege) => {
  const instructorToken = (item) => item === "ac-console-5";
  return userPrivilege
    ? findIndex(instructorToken, userPrivilege) !== -1
    : false;
};

export const getLoginStatus = (ctx) => {
  const { avatar, token, email, loginType, name } = getCookies(ctx) || {};
  return {
    token: token || "",
    email: email || "",
    avatar: avatar ? decodeURIComponent(avatar) : "",
    name: name ? decodeURIComponent(name) : "",
    loginType: loginType || "",
  };
};

export const redirectToDestination = ({
  destination = appRootPath,
  reason,
} = {}) => {
  const reasonHashTag = reason ? `#${reason}` : "";
  const destinationValue = `${destination}${reasonHashTag}`;
  return {
    redirect: {
      permanent: false,
      destination: destinationValue,
    },
  };
};

export const generatePageProps = ({
  pageData,
  accessPageKey = "",
  customProps = {},
  customRedirection = null,
}) => {
  const { theme, userPrivilege, url, loginStatus } = pageData;
  const { email = "unknown", token } = loginStatus;
  const tokenValid = isTokenAvailable(token);
  const isRootUrl = url.includes(appRootPath);

  if (!checkPageIsPublic(theme) && !tokenValid && !isRootUrl) {
    console.log(`[CLIENT_PAGE_DENY],${email},${url},401`);
    return {
      ...redirectToDestination({
        reason: "401",
      }),
      props: {
        data: pageData,
      },
    };
  }
  if (accessPageKey) {
    if (!checkPagePermit({ privilege: userPrivilege, accessPageKey })) {
      console.log(`[CLIENT_PAGE_DENY],${email},${url},403`);
      return {
        ...redirectToDestination({
          destination: tokenValid ? "/" : undefined,
          reason: "403",
        }),
        props: {
          data: pageData,
        },
      };
    }
  }

  return {
    ...customRedirection,
    props: {
      ...customProps,
      data: pageData,
    },
  };
};

export const pageAccess = async (ctx, theme) => {
  const loginStatus = getLoginStatus(ctx);
  const { data: privilegeData } = loginStatus.token
    ? await getUserPrivilege(loginStatus)
    : {};
  const { data: userPrivacyData } = loginStatus.token
    ? await getUserPrivacy(loginStatus)
    : {};
  const userPrivacy = userPrivacyData || {};
  const userPrivilege = privilegeData || [];
  return {
    url: ctx.req.url,
    loginStatus,
    theme,
    userPrivilege,
    userPrivacy: {
      activity: userPrivacy.activity === "true",
      enrollment: userPrivacy.enrollment === "true",
      information: userPrivacy.information === "true",
      location: userPrivacy.location === "true",
    },
  };
};

export const getCourseContentUrl = async ({
  courseId = "",
  learnContentId = null,
  getUserUnfinishedField,
  getEnrollStatus,
  getLearningSyllabus = async () => {
    // This is intentional
  },
}) => {
  try {
    if (typeof getUserUnfinishedField === "function") {
      const {
        data: getUserUnfinishedFieldData,
        error: getUserUnfinishedFieldError,
      } = await getUserUnfinishedField();
      if (!getUserUnfinishedFieldError && getUserUnfinishedFieldData?.length)
        return {
          url: `/myJournal/complete?${qs.stringify({
            courseId,
            learnContentId,
          })}`,
        };
    }
    if (typeof getEnrollStatus === "function") {
      const { data: enrollStatus, error: enrollError } = await getEnrollStatus({
        itemType: "course",
        itemId: courseId,
      });
      if (!enrollError && !enrollStatus) {
        return {
          url: `/course/preview/${courseId}`,
          error: "courseHasNotBeenEnrolled",
        };
      }
    }
    const { data, error } = await getLearningSyllabus();
    if (error) {
      return {
        url: "/explore",
        error: "courseNotFound",
      };
    }
    const dataContent = data.content || data.courseContent;
    const {
      chapters = [],
      preview = {},
      previewVideo = {},
    } = dataContent || {};
    const previewItem = preview[0] || previewVideo;
    const itemArray = [];
    if (previewItem.id) {
      itemArray.push({ ...previewItem, type: "video" });
    }
    chapters.forEach((chapter) => {
      chapter.items.forEach((item) => {
        itemArray.push(item);
      });
    });
    const isTarget = (item) => item.id === learnContentId;
    /**
     * learnContentId may be lost due to the course rePublish or removal
     */
    const itemTarget =
      itemArray.find(isTarget) || dataContent?.chapters[0].items[0];

    if (itemTarget) {
      return {
        url: `/course/${courseId}/${itemTarget.type}/${itemTarget.id}`,
      };
    }
    return {
      url: `/course/preview/${courseId}`,
      error: "contentNotFound",
    };
  } catch (error) {
    console.error("getCourseContentUrl", error);
    return {
      url: "/explore",
      error: "courseNotFound",
    };
  }
};

export const checkPagePermit = ({
  privilege = [],
  accessPageKey = "",
} = {}) => {
  if (privilege.length === 0 && !accessPageKey) {
    return true;
  }
  if (accessPageKey) {
    // check accessPageKey
    const privilegeKey = privilege.map((item) => item);
    const authorizedKey = pagePermitKeyList[accessPageKey]?.permissionKey;
    if (authorizedKey) {
      return privilegeKey.includes(authorizedKey);
    }
    console.warn(`[PAGE_PERMIT] due to ${accessPageKey} is not defined`);
    // enter an undefined page
    return true;
  }
  // enter an open page
  return true;
};

export const getStudioCustomRedirection = ({ pageData, apiError = false }) => {
  const { userPrivilege } = pageData;
  const isProvider = checkIsProvider(userPrivilege);
  const isApiError = !!apiError;
  if (isApiError && isProvider) {
    return redirectToStudioLandingInGetServerSideProps;
  }
  if (!isProvider) {
    return redirectToExplore;
  }
  return null;
};

export const redirectToStudioLandingInGetServerSideProps = {
  redirect: {
    permanent: false,
    destination: `/studio/courseware/myCourse`,
  },
};

export const redirectToExplore = {
  redirect: {
    permanent: false,
    destination: "/explore",
  },
};

export const consoleDirectPage = ({ useTmsAdapter, userPrivilege }) => {
  if (useTmsAdapter) {
    return "/console/learner-progress-report";
  }
  if (userPrivilege.includes("ac-console-2")) {
    return "/console/overview-dashboard";
  }
  if (userPrivilege.includes("ac-console-13")) {
    return "/console/course-overview-report";
  }
  return "/";
};
